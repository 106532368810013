<template>
  <div class="MERCH userQuaLiFicaTionList">
    <div class="merch-card">
      <!-- tag标签 -->
      <div class="merch-tag">
        <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
          <el-tab-pane label="会员资质" name="0"> </el-tab-pane>
          <el-tab-pane label="资质库" name="1"></el-tab-pane>
        </el-tabs>
      </div>
      <template v-if="activeName == 0">
        <!-- 列表 -->
        <div
          :style="`max-height: ` + mainHright + `px`"
          class="merch-table scorr-roll"
        >
          <UserType />
        </div>
        <!-- 分页 -->
        <div class="merch-page">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="setQaList.per_page"
            layout="total, prev, pager, next, jumper"
            :total="setQaList.total"
            :current-page="EnterpriseApi.page"
          >
          </el-pagination>
        </div>
      </template>
      <template v-if="activeName == 1">
        <!-- 搜索列 -->
        <div class="merch-search">
          <div class="search-box">
            <div class="sch-1 sch-2">
              <div class="sch-title">资质名称:</div>
              <el-input
                @change="toSearch"
                placeholder="请输入资质名称"
                v-model="EnterpriseApi.name"
                clearable
              ></el-input>
            </div>
            <div class="sch-1 sch-2">
              <div class="sch-title">资质状态:</div>
              <el-select
                v-model="EnterpriseApi.status"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in EnterpriseApiOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="sch-1 sch-2">
              <el-button type="primary" size="mini" @click="toSearch">
                查 询
              </el-button>
              <el-button type="primary" size="mini" @click="toAdd">
                新建
              </el-button>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div
          :style="`max-heigh: ` + mainHright + `px`"
          class="merch-table scorr-roll"
        >
          <UserBank />
        </div>
        <!-- 分页 -->
        <div class="merch-page" v-if="activeName == 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="setQaList.per_page"
            layout="total, prev, pager, next, jumper"
            :total="setQaList.total"
          >
          </el-pagination>
        </div>
        <div class="merch-page" v-if="activeName == 1">
          <el-pagination
            background
            @current-change="handleCurrentChange1"
            :page-size="UserQualificationData.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="UserQualificationData.total"
            :current-page="EnterpriseApi.currPage"
          >
          </el-pagination>
        </div>
      </template>
    </div>
    <!-- 新建资质 -->
    <div>
      <el-dialog
        title="创建会员资质"
        :visible.sync="isQshow"
        width="550px"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <div>
          <el-form ref="form" label-width="150px">
            <el-form-item label="会员资质编号">
              <el-input
                style="width: 300px"
                v-model="AddQuaData.qualificationTypeCode"
                placeholder="会员资质编号"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="资质名称">
              <el-input
                style="width: 300px"
                v-model="AddQuaData.qualificationName"
                placeholder="资质名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="排序">
              <el-input
                style="width: 300px"
                v-model="AddQuaData.sequence"
                type="number"
                oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                placeholder="排序"
              ></el-input>
            </el-form-item>
            <el-form-item label="图片张数">
              <el-input
                style="width: 300px"
                v-model="AddQuaData.imgNumber"
                placeholder="图片数量"
                type="number"
                oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
              ></el-input>
            </el-form-item>
            <el-form-item label="关联经营类别">
              <el-select
                v-model="AddQuaData.businessCategoryIds"
                placeholder="请选择"
                multiple
                clearable
                style="width: 300px"
              >
                <el-option
                  v-for="item in businessCateList"
                  :key="item.id"
                  :label="item.type"
                  :value="item.id"
                  :disabled="item.typeId != 0"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="AddQuaData.requiredType"
                active-color="#06B7AE"
                inactive-color="#C9CDD4"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
            <el-form-item label="状态">
              <el-switch
                v-model="AddQuaData.enableStatus"
                active-color="#06B7AE"
                inactive-color="#C9CDD4"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="notarizeAdd">提 交</el-button>
          <el-button @click="isQshow = false" class="huise">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapActions, mapState } = createNamespacedHelpers("Basics"); //vuex公共库
import UserType from "./userQ/userType.vue"; //上架资质列表
import UserBank from "./userQ/userBank.vue"; //资质库列表
export default {
  components: {
    UserType,
    UserBank,
  },
  computed: {
    ...mapState(["setQaList", "UserQualificationData"]),
    ...commonIndex.mapState(["mainHright", "allJurisdiction", "detailsSeach"]),
  },
  data() {
    return {
      searchData: {},
      activeName: 0,
      IsAddqualification: false,
      form: {},
      value: "",
      options: [],
      EnterpriseApi: {
        name: "",
        page: 1,
        status: "",
      },
      EnterpriseApiOptions: [
        {
          value: "0",
          label: "停用",
        },
        {
          value: "1",
          label: "启用",
        },
      ],
      isQshow: false,
      AddQuaData: {
        name: "",
        sort: "",
        is_required: 0,
        status: 0,
      }, //新增资质数据
      businessCateList: [],
    };
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.EnterpriseApi = this.detailsSeach[router];
      this.activeName = this.detailsSeach[router].activeName;
    }
    this.getEnterpriseTypelits();
  },
  methods: {
    ...mapActions([
      "postaddbusinessQualification",
      "getmemberTypelist",
      "getmemberLibrarylist",
      "postmemberLibrarycreate",
      "getuserQualificationGetLastCode",
      "postuserQualificationAdd",
      "getAllBusinessCategory",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    // 确认新建
    async notarizeAdd() {
      if (
        this.AddQuaData.qualificationName == "" ||
        this.AddQuaData.sequence == "" ||
        this.AddQuaData.imgNumber == "" ||
        this.AddQuaData.requiredType === "" ||
        this.AddQuaData.enableStatus === ""
      ) {
        this.$message({
          message: "资料未填写完整",
          type: "warning",
        });
        return;
      }
      if (this.AddQuaData.imgNumber > 5) {
        this.$message({
          message: "图片数量不能大于5",
          type: "warning",
        });
        return false;
      }
      this.AddQuaData.qualificationTypeCode =
        this.AddQuaData.qualification_type_code;
      let data = await this.postuserQualificationAdd(this.AddQuaData);
      if (data.code == "000000") {
        this.isQshow = false;
        this.$message({ message: data.message, type: "success" });
        this.EnterpriseApi.currPage = 1;
        this.getEnterpriseTypelits();
        this.toSearch();
      } else {
        this.$message({ message: data.message, type: "warning" });
      }
    },
    // 打开新建
    async toAdd() {
      let res = await this.getuserQualificationGetLastCode();
      await this.getAllBusinessCategory().then((res) => {
        this.businessCateList = res.content;
      });
      this.isQshow = true;
      this.AddQuaData = {
        qualification_type_code: res.data,
        qualification_name: "",
        sequence: "",
        img_number: "",
        required_type: "",
        enable_status: "",
      };
    },
    // 获取资质库列表
    getEnterpriseTypelits() {
      this.getmemberTypelist(this.EnterpriseApi);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.EnterpriseApi };
      save.router = router;
      save.activeName = this.activeName;
      this.changeSeach(save);
    },
    handleCurrentChange1(e) {
      this.EnterpriseApi.currPage = e;
      this.EnterpriseApi.pageSize = 10;
      this.getmemberLibrarylist(this.EnterpriseApi);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.EnterpriseApi };
      save.router = router;
      save.activeName = this.activeName;
      this.changeSeach(save);
    },
    handleCurrentChange(e) {
      this.EnterpriseApi.page = e;
      this.getEnterpriseTypelits();
    },
    toSearch() {
      this.EnterpriseApi.currPage = 1;
      this.EnterpriseApi.pageSize = 10;
      this.getmemberLibrarylist(this.EnterpriseApi);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.EnterpriseApi };
      save.router = router;
      save.activeName = this.activeName;
      this.changeSeach(save);
    },
    // tag标签切换
    handleClick(tab) {
      if (tab == 1) {
        this.EnterpriseApi = {
          currPage: 1,
        };
        this.EnterpriseApi.pageSize = 10;
        this.getmemberLibrarylist(this.EnterpriseApi);
        let router = this.$router.history.current.path.substr(1);
        let save = { ...this.EnterpriseApi };
        save.router = router;
        save.activeName = this.activeName;
        this.changeSeach(save);
      } else {
        this.EnterpriseApi = {
          page: 1,
        };
        this.getEnterpriseTypelits();
        let router = this.$router.history.current.path.substr(1);
        let save = { ...this.EnterpriseApi };
        save.router = router;
        save.activeName = this.activeName;
        this.changeSeach(save);
      }
    },
  },
};
</script>
<style style lang="scss">
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;

  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .TRAN-BOX {
      width: 100%;
      height: 600px;
    }
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      height: 50px;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      // max-height: 60vh;
      // height: 200px;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
.huise {
  background-color: #f4f4f5;
  border: 1px solid #f4f4f5;
  color: #383b46;
}
</style>
