<template>
  <div>
    <div>
      <el-table :data="setQaList.data" border style="width: 100%" >
        <el-table-column prop="id" label="编号" align="center">
        </el-table-column>
        <el-table-column prop="name" label="会员类型" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.type_name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" align="center">
        </el-table-column>
        <el-table-column prop="number" label="注册资质数" align="center">
          <template slot-scope="{ row }">
            <div class="nobor">
              <div
                class="baby"
                @mouseover="mouseOver(row, 2)"
                @mouseleave="mouseLeave(row, 2)"
              >
                {{ row.number }}
              </div>
              <div
                :class="
                  row.index >= setQaList.data.length / 2
                    ? 'immigration3'
                    : 'immigration2'
                "
                v-if="row.is_display"
                class="immigration"
              >
                <div v-for="(item, index) in row.list" :key="index">
                  {{ item.qualification_name }}
                </div>
              </div>
              <div></div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="number" label="注册资质数" align="center">
          <template slot-scope="{ row }">
            <div class="userBtn">
              <el-tooltip
                class="item"
                effect="dark"
                :content="tooltipContent"
                placement="bottom"
              >
                <el-button class="nobor">{{ row.number }}</el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="address" label="操作" align="center" width="250">
          <template slot-scope="{ row }">
            <div class="userBtn">
              <div
                @click="deploy(row)"
                v-if="allJurisdiction.userQuaLiFicaTionList48"
              >
                配置
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 配置资质 -->
    <div>
      <el-dialog
        title="选择会员入驻需要资质"
        :visible.sync="isdeploy"
        width="800px"
      >
        <div>
          <!-- 搜索列 -->
          <div class="dialog-head">
            <div>会员类型编号：{{ deployMessage.user_code }}</div>
            <div>会员类型：{{ deployMessage.type_name }}</div>
            <div>状态：{{ deployMessage.status_name }}</div>
            <!-- <div class="search-box">
              <div class="sch-1 sch-2">
                <div class="sch-title">资质状态:</div>
                <el-select
                  v-model="deployApi.status"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in userType"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="sch-1 sch-2">
                <div class="sch-title">资质名称:</div>
                <el-input
                  placeholder="请输入资质名称"
                  v-model="deployApi.keyword"
                ></el-input>
              </div>
              <div class="sch-1 sch-2 sch-btn">
                <el-button type="primary" size="mini" @click="toSearch">
                  搜索
                </el-button>
              </div> -->
            <!-- </div> -->
          </div>
          <div class="required-qualifications">
            <div class="choseform-name">入驻所需资质:</div>
            <div class="choseform">
              <div class="choseform-head">
                资质库
                <span
                  >已选:{{ qualification_code.length }}/{{
                    atDeplyData.length
                  }}</span
                >
              </div>
              <div class="choseform-form">
                <!-- <el-input
                  placeholder="请输入内容"
                  v-model="choseforminput"
                  clearable
                  class="choseform-input"
                  @input='choseformchage'
                >
                </el-input> -->
                <div class="choseform-Checkbox">
                  <div v-for="(v, i) in atDeplyData" :key="i">
                    <el-checkbox
                      v-model="v.Checkbox"
                      @change="changeCheckbox"
                      >{{ v.qualification_name }}</el-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 列表 -->
          <!-- <div class="table-src scorr-roll">
            <el-table :data="deplyData.data" border style="width: 100%">
              <el-table-column prop="id" label="编号" align="center">
              </el-table-column>
              <el-table-column prop="name" label="注册资质" align="center">
              </el-table-column>
              <el-table-column
                prop="address"
                label="是否需要提供资质"
                align="center"
                width="250"
              >
                <template slot-scope="{ row }">
                  <el-switch
                    @change="changeSwitch(row)"
                    v-model="row.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="1"
                    :inactive-value="0"
                  >
                  </el-switch>
                </template>
              </el-table-column>
            </el-table>
          </div> -->
          <!-- 分页 -->
          <!-- <div class="merch-page">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :page-size="deplyData.per_page"
              layout="total, prev, pager, next, jumper"
              :total="deplyData.total"
            >
            </el-pagination>
          </div> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isdeploy = false">取 消</el-button>
          <el-button type="primary" @click="sureSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState, mapMutations } =
  createNamespacedHelpers("Basics"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      deployApi: {
        page: 1,
      },
      deplyData: {},
      atDeplyData: {},
      qualification_code: [],
      isdeploy: false,
      tooltipContent: "",
      deployMessage: {},
      choseforminput: "",
      userType: [
        {
          type: 1,
          name: "开",
        },
        {
          type: 0,
          name: "关",
        },
      ],
    };
  },
  computed: {
    ...mapState(["setQaList"]),
    ...commonIndex.mapState(["allJurisdiction"]),
  },
  methods: {
    ...mapActions([
      "postmemberTypeLibrarylist",
      "postmemberTypeLibraryset",
      "getqualificationGrouplist",
      "postqualificationGroupsete",
    ]),
    ...mapMutations(["cguserdisplay"]),
    mouseOver(val) {
      let params = {
        row: val,
        show: true,
      };
      this.cguserdisplay(params);
    },
    mouseLeave(val) {
      let params = {
        row: val,
        show: false,
      };
      this.cguserdisplay(params);
      // console.log(val);
    },
    // 配置
    async deploy(val) {
      this.deployMessage = val;
      if (val) {
        this.deployApi.type_code = val.user_code;
      }
      let data = await this.getqualificationGrouplist(this.deployApi);
      if (data.code == 200) {
        data.data.forEach((val) => {
          if (val.id == null) {
            val.Checkbox = false;
          } else {
            val.Checkbox = true;
          }
        });
        let list = data.data.filter((v) => {
          return v.Checkbox;
        });
        this.qualification_code = list;
        this.deplyData = data.data;
        this.atDeplyData = this.deplyData;
        this.isdeploy = true;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 开关改变
    async changeSwitch(row) {
      let data = await this.postmemberTypeLibraryset({
        user_qualify_type_id: this.deployApi.user_qualify_type_id,
        id: row.id,
        status: row.status,
      });
      if (data.code == 200) {
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
      this.deploy();
    },
    choseformchage() {
      // console.log(this.choseforminput)
      // let list=this.deplyData.filter(val=>{
      //   return val.qualification_name
      // })
      // this.deplyData.forEach(v=>{
      //   console.log(v)
      //   v.dim=v.qualification_name.split()
      //   console.log(123,v)
      // })
    },
    changeCheckbox() {
      let list = this.deplyData.filter((val) => {
        return val.Checkbox;
      });
      // this.deplyData.forEach(v=>{
      //   if(v.Checkbox){
      //     this.qualification_code.push(v.qualification_type_code)
      //   }
      // })
      this.qualification_code = [];
      list.forEach((v) => {
        if (v.Checkbox) {
          this.qualification_code.push(v.qualification_type_code);
        }
      });
    },
    // 确定
    async sureSubmit() {
      if (this.qualification_code.length == 0) {
        this.$message({
          message: "请至少选择一项",
          type: "warning",
        });
        return false;
      }
      let qualificationCode = [];
      if (this.qualification_code[0].qualification_type_code) {
        this.qualification_code.forEach((item) => {
          qualificationCode.push(item.qualification_type_code);
        });
      } else {
        qualificationCode = this.qualification_code;
      }
      let params = {
        user_type: this.deployMessage.type_name,
        user_code: this.deployMessage.user_code,
        qualification_code: qualificationCode,
      };
      let res = await this.postqualificationGroupsete(params);
      if (res.code == "200") {
        this.$parent.getEnterpriseTypelits();
        this.isdeploy = false;
      }
    },
    toSearch() {
      this.deployApi.page = 1;
      this.deploy();
    },
    handleCurrentChange(e) {
      this.deployApi.page = e;
      this.deploy();
    },
  },
};
</script>
<style lang='scss' scoped>
.checkbox-must .el-checkbox__label {
  color: #494c57;
}
.input-1 .el-input {
  border-radius: 4px;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.type-title {
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  span {
    margin-left: 10px;
    font-size: 17px;
    font-weight: 600;
  }
}
.box-1 {
  max-height: 300px;
}
.entering-box {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .entering {
    border-radius: 4px;
    width: 75%;
    height: 100%;
    border: 1px solid #cdd0d6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    .input-1 {
      width: 150px;
      border-radius: 4px;
    }
  }
  .add-nuis {
    width: 15%;
    padding: 0px 5px;
    display: flex;
    justify-content: space-between;
    .add {
      color: #1abca9;
    }
    .minus {
      color: #1abca9;
    }
  }
}
// 搜索行样式
.merch-search {
  margin-top: 1%;
  width: 100%;
  height: 50px;
  .search-box {
    display: flex;
    flex-wrap: wrap;
  }
  .sch-1 {
    width: 18%;
    min-width: 260px;
    display: flex;

    .sch-title {
      width: 40%;
      min-width: 80px;
      height: 100%;
      font-size: 13px;
      background-color: #f5f7fa;
      color: #909399;
      border: 1px solid #dcdfe6;
      border-right: none;
      border-radius: 4px 0px 0px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 0px 0px 5px !important;
    }
  }
  .sch-2 {
    margin-right: 2%;
  }
  .sch-btn {
    width: 60px;
    min-width: 60px;
  }
}
.merch-page {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: right;
}
.table-src {
  height: 300px;
}
.nobor {
  border: none;
}
.dialog-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.required-qualifications {
  display: flex;
}
.choseform-name {
  line-height: 34px;
}
.choseform {
  flex: 1;
  border: 1px solid #ccc;
  margin-left: 20px;

  .choseform-form {
    padding: 10px;
  }
  .choseform-input {
    width: 200px;
  }
  .choseform-Checkbox {
    overflow-y: scroll;
    height: 100px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin-top: 10px;
    > div {
      height: 24px;
      line-height: 24px;
    }
  }
}

.nobor {
  position: relative;
  .immigration {
    position: absolute;

    z-index: 99;
    left: 50%;
    width: 200px;
    color: #fff;
    // height: 100px;
    margin-left: -100px;
    background-color: #909399;
  }
  .immigration0 {
    top: 25px;
  }
  .immigration1 {
    bottom: 25px;
  }

  .immigration2 {
    top: 25px;
  }
  .immigration3 {
    bottom: 25px;
  }
}
.choseform-head {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background-color: #ccc;
  padding: 10px;
}
::v-deep .el-checkbox__label {
  color: #909399;
}
::v-deep .el-table .cell {
  overflow: visible !important;
}
</style>
