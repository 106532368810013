<template>
  <div>
    <div>
      <el-table
        :data="UserQualificationData.list"
        border
        style="width: 100%"
        height="600"
      >
        <el-table-column
          prop="qualificationTypeCode"
          label="编号"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="name" label="资质名称" align="center">
          <template slot-scope="{ row }">
            <div class="exceed">
              {{ row.qualificationName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="关联经营类别"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <div class="exceed">
              {{ row.businessCategory || "/" }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="sort" label="排序" align="center">
          <template slot-scope="{ row }">
            <div class="exceed_color">
              {{ row.sequence }}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="address" label="是否必填" align="center">
          <template slot-scope="{ row }">
            <div class="exceed_color">
              {{ row.requiredType == 1 ? "是" : "否" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="图片数量" align="center">
          <template slot-scope="{ row }">
            <div class="exceed_color">
              {{ row.imgNumber }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="address" label="状态" align="center">
          <template slot-scope="{ row }">
            <div class="exceed_color exceed">
              {{ row.enableStatus == 1 ? "启用" : "停用" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" width="250">
          <template slot-scope="{ row }">
            <div class="userBtn">
              <el-popconfirm
                title="确定停用当前资质吗？"
                @confirm="OnOff(row)"
                v-if="
                  row.enableStatus == 1 &&
                  allJurisdiction.userQuaLiFicaTionList95
                "
              >
                <el-button slot="reference" @click.stop="" class="nobuttom">
                  停用</el-button
                >
              </el-popconfirm>
              <el-popconfirm
                title="确定启用当前资质吗？"
                @confirm="OnOff(row)"
                v-if="
                  row.enableStatus != 1 &&
                  allJurisdiction.userQuaLiFicaTionList95
                "
              >
                <el-button slot="reference" @click.stop="" class="nobuttom">
                  启用</el-button
                >
              </el-popconfirm>
              <!-- <div @click="OnOff(row)">{{ row.status == 1 ? "停用" : "启用" }}</div> -->
              <div
                @click="redact(row)"
                v-if="allJurisdiction.userQuaLiFicaTionList96"
              >
                编辑
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 修改资质 -->
    <div>
      <el-dialog
        title="编辑会员资质"
        :visible.sync="isQshow"
        width="550px"
        @close="closeDialog"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <div>
          <el-form ref="form" label-width="150px">
            <el-form-item label="会员资质编号">
              <el-input
                style="width: 300px"
                v-model="AddQuaData.qualificationTypeCode"
                placeholder="会员资质编号"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="会员资质名称">
              <el-input
                style="width: 300px"
                v-model="AddQuaData.qualificationName"
                placeholder="会员资质名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="排序">
              <el-input
                style="width: 300px"
                v-model="AddQuaData.sequence"
                placeholder="排序"
                type="number"
                oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
              ></el-input>
            </el-form-item>
            <el-form-item label="图片张数">
              <el-input
                style="width: 300px"
                v-model="AddQuaData.imgNumber"
                placeholder="图片张数"
                type="number"
                oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
              ></el-input>
            </el-form-item>
            <el-form-item label="关联经营类别">
              <el-select
                v-model="AddQuaData.businessCategoryIds"
                placeholder="请选择"
                multiple
                clearable
                style="width: 300px"
              >
                <el-option
                  v-for="item in businessCateList"
                  :key="item.id"
                  :label="item.type"
                  :value="item.id"
                  :disabled="item.typeId != 0"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="AddQuaData.requiredType"
                active-color="#06B7AE"
                inactive-color="#C9CDD4"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
            <el-form-item label="状态">
              <el-switch
                v-model="AddQuaData.enableStatus"
                active-color="#06B7AE"
                inactive-color="#C9CDD4"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="notarizeAdd">提 交</el-button>
          <el-button @click="isQshow = false" class="huise">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("Basics"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      isdeploy: false,
      isQshow: false,
      AddQuaData: {},
      businessCateList: [],
    };
  },
  computed: {
    ...mapState(["UserQualificationData"]),
    ...commonIndex.mapState(["allJurisdiction"]),
  },
  methods: {
    ...mapActions([
      "postmemberTypeLibraryedit",
      "getmemberTypelist",
      "getmemberTypeLibraryedit",
      "getAllBusinessCategory",
      "postuserQualificationAdd",
      "puteditStatus",
      "getmemberLibrarylist",
    ]),
    // 配置
    async redact(val) {
      val.sort = val.sort + "";
      this.AddQuaData = { ...val };
      await this.getAllBusinessCategory().then((res) => {
        this.businessCateList = res.content;
      });
      this.isQshow = true;
    },
    async OnOff(val) {
      let params = {};
      params.status = !val.enableStatus ? 1 : 0;
      params.id = val.id;
      let data = await this.puteditStatus(params);
      if (data.code == "000000") {
        this.$message({ message: data.message, type: "success" });
        this.getmemberLibrarylist(this.$parent.EnterpriseApi);
      } else {
        this.$message({ message: data.message, type: "warning" });
      }
    },
    // 确认编辑
    async notarizeAdd() {
      //console.log(this.AddQuaData.name == "");
      //console.log(this.AddQuaData.sort == "");
      if (this.AddQuaData.name == "" || this.AddQuaData.sort == "") {
        this.$message({
          message: "资料未填写完整",
          type: "warning",
        });
        return;
      }
      if (this.AddQuaData.imgNumber > 5) {
        this.$message({
          message: "图片数量不能大于5",
          type: "warning",
        });
        return false;
      }
      let data = await this.postuserQualificationAdd(this.AddQuaData);
      if (data.code == "000000") {
        this.isQshow = false;
        this.$message({ message: data.message, type: "success" });
        this.getmemberLibrarylist(this.$parent.EnterpriseApi);
      } else {
        this.$message({ message: data.message, type: "warning" });
      }
    },
    closeDialog() {
      this.$parent.toSearch();
      // this.getEnterpriseTypelits();
    },
  },
};
</script>
<style lang='scss' scoped>
.checkbox-must .el-checkbox__label {
  color: #494c57;
}
.input-1 .el-input {
  border-radius: 4px;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.type-title {
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  span {
    margin-left: 10px;
    font-size: 17px;
    font-weight: 600;
  }
}
.box-1 {
  max-height: 300px;
}
.entering-box {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .entering {
    border-radius: 4px;
    width: 75%;
    height: 100%;
    border: 1px solid #cdd0d6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    .input-1 {
      width: 150px;
      border-radius: 4px;
    }
  }
  .add-nuis {
    width: 15%;
    padding: 0px 5px;
    display: flex;
    justify-content: space-between;
    .add {
      color: #1abca9;
    }
    .minus {
      color: #1abca9;
    }
  }
}
.nobuttom {
  border: none;
  padding: 0;
  color: #1abca9;
}
.huise {
  background-color: #f4f4f5;
  border: 1px solid #f4f4f5;
  color: #383b46;
}
</style>
