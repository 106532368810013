var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.setQaList.data,"border":""}},[_c('el-table-column',{attrs:{"prop":"id","label":"编号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"name","label":"会员类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.type_name))])]}}])}),_c('el-table-column',{attrs:{"prop":"sort","label":"排序","align":"center"}}),_c('el-table-column',{attrs:{"prop":"number","label":"注册资质数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"nobor"},[_c('div',{staticClass:"baby",on:{"mouseover":function($event){return _vm.mouseOver(row, 2)},"mouseleave":function($event){return _vm.mouseLeave(row, 2)}}},[_vm._v(" "+_vm._s(row.number)+" ")]),(row.is_display)?_c('div',{staticClass:"immigration",class:row.index >= _vm.setQaList.data.length / 2
                  ? 'immigration3'
                  : 'immigration2'},_vm._l((row.list),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item.qualification_name)+" ")])}),0):_vm._e(),_c('div')])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"操作","align":"center","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('div',{staticClass:"userBtn"},[(_vm.allJurisdiction.userQuaLiFicaTionList48)?_c('div',{on:{"click":function($event){return _vm.deploy(row)}}},[_vm._v(" 配置 ")]):_vm._e()])]}}])})],1)],1),_c('div',[_c('el-dialog',{attrs:{"title":"选择会员入驻需要资质","visible":_vm.isdeploy,"width":"800px"},on:{"update:visible":function($event){_vm.isdeploy=$event}}},[_c('div',[_c('div',{staticClass:"dialog-head"},[_c('div',[_vm._v("会员类型编号："+_vm._s(_vm.deployMessage.user_code))]),_c('div',[_vm._v("会员类型："+_vm._s(_vm.deployMessage.type_name))]),_c('div',[_vm._v("状态："+_vm._s(_vm.deployMessage.status_name))])]),_c('div',{staticClass:"required-qualifications"},[_c('div',{staticClass:"choseform-name"},[_vm._v("入驻所需资质:")]),_c('div',{staticClass:"choseform"},[_c('div',{staticClass:"choseform-head"},[_vm._v(" 资质库 "),_c('span',[_vm._v("已选:"+_vm._s(_vm.qualification_code.length)+"/"+_vm._s(_vm.atDeplyData.length))])]),_c('div',{staticClass:"choseform-form"},[_c('div',{staticClass:"choseform-Checkbox"},_vm._l((_vm.atDeplyData),function(v,i){return _c('div',{key:i},[_c('el-checkbox',{on:{"change":_vm.changeCheckbox},model:{value:(v.Checkbox),callback:function ($$v) {_vm.$set(v, "Checkbox", $$v)},expression:"v.Checkbox"}},[_vm._v(_vm._s(v.qualification_name))])],1)}),0)])])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.isdeploy = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sureSubmit}},[_vm._v("确 定")])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }